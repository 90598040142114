
export const INT_URL = 'http://localhost/newCRM/public/';
export const EXT_URL = 'https://crm.adoctor.in/api/public/';
export const API_PATH = 'api/';
export const USER_API_PATH = 'user/';
export const PUB_API_PATH = 'pub/';
export const ENQUIRY_PATH = 'enquiry/';
export const ENQUPDATE_PATH = 'enquiry_update/';
export const CLIENT_PATH = 'client/';
export const CATEGORY_PATH = 'category/';
export const LOG_PATH = 'logs_api/';
export const SOURCE_PATH = 'source/';
export const APPOINTMENT_PATH = 'appointment/';

export const REGEX_EMAIL = '^[a-zA-Z0-9.]+@[a-zA-Z0-9-]+[\.]+[a-zA-Z0-9]+(?:\.[a-zA-Z0-9-]+)*$';
export const REGEX_PHONE = /^(\+\d{1,3}[- ]?)?\d{10}$/;
export const REGEX_PASSWORD = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{4,}/;
export const REGEX_PAN = "[A-Z]{5}[0-9]{4}[A-Z]{1}";
export const REGEX_GST = " \d{ 2}[A-Z]{ 5 } \d{ 4 } [A - Z]{ 1 } [A - Z\d]{ 1 } [Z]{ 1 } [A - Z\d]{ 1 }";
export const REGEX_PIN = "[1-9][0-9]{5}"

export const DATA_EXPIRY_HOURS = 1;
